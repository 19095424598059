import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import About from './components/about/About';
import Home from './components/home/Home';
import RoadMap from './components/road-map/RoadMap';
import Team from './components/team/Team';
import Teaser from './components/teaser/Teaser';
import Faq from './components/faq/Faq';
import Backdrop from './components/backdrop/Backdrop';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}



function App() {
    return ( < div className = "container" > < Backdrop / > 
        <Router>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path='*' element={<Home/>} />
            </Routes>
      </Router>
      < /div>)
}
export default App;