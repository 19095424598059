import React from 'react'
import ReactDOM from 'react-dom'
import nakachads from '../../nakachads';
import web3 from '../../web3';
import axios from 'axios';
import teaser from '../../assets/teaser.gif';
import raritySniper from '../../assets/raritysniper.jpeg';
import openSea from '../../assets/opensea.png';
import ImageGallery from 'react-image-gallery';

let images = [
];



let infos = {
    wallet: undefined,
    networkId: 0

}

const state = {
    nft: []
}
if (window.ethereum) {
    window.ethereum.on('accountsChanged', function (accounts) {
          console.log('accountsChanges',accounts);
              window.location.reload(true);
    });

             // detect Network account change
    window.ethereum.on('chainChanged', function(networkId){
          console.log('chainChanged',networkId);
              window.location.reload(true);
    });
}


const mintSale = async() => {
    try {
        //let fastMint = document.getElementById("checkboxFastMint").checked;
        
        console.log(`start minting ...`)
        let amount = 3;
        let tx;
        
            let bal = await web3.eth.getBalance(infos.clientAccount[0]);
            bal = web3.utils.fromWei(bal, "ether");
            //console.log(bal)
            console.log(`get gas estimate `)

            const resGasMethod = await nakachads.methods.mint(amount).estimateGas({from: infos.clientAccount[0],value: 0});
            const latestBlock : any = await web3.eth.getBlock('latest');
            const blockGas = latestBlock.gasLimit;
            const finalGas = (blockGas * resGasMethod);
            const finalGasInEther = web3.utils.fromWei(finalGas.toString(), 'ether');
            
            //console.log(finalGas)
            //console.log(latestBlock.baseFeePerGas)
            //console.log(latestBlock.baseFeePerGas*0.8).toFixed(0))

            let maxGas = 27930846678;
            let minGas = 11930846678
            let lastBlock = (latestBlock.baseFeePerGas).toFixed(0);


            if(lastBlock < minGas) {
                lastBlock = minGas
            }

            /*if(bal < 0.1) {
                lastBlock = 11930846678
            }
            if(bal < 0.5) {
                lastBlock = 9330846678
            }*/

            console.log(`creating tx...` )

             tx = {   
                gasPrice: lastBlock,
                gas: (resGasMethod*1.3).toFixed(0),
                from: infos.clientAccount[0],
                value: 0
            };

            console.log(tx)

    
        await nakachads.methods.mint(amount).send(tx
        
        , (err, res) => {
        if (err) {
            
          console.log(err)
           ReactDOM.render(err.message, document.getElementById('error'))
          return
        } else {
                let hashTXLink = 'https://etherscan.io/tx/'+res
                let hashTXLinks = (<i>Sucessfull created transaction <a href={hashTXLink}>{hashTXLink}</a></i>)
                ReactDOM.render(hashTXLinks, document.getElementById('error'))
                
            }
      })
    } catch (ex) {
        console.log(`errror in minting`)
        console.log(ex)
       ReactDOM.render(ex.message, document.getElementById('error')) 
    }
}

const connectMM = async() => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        infos.wallet = accounts[0]
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }

        console.log(error);
      }
    }
 
}


const start = async() => {
        let idOfNetwork = undefined
    try {
        idOfNetwork = await web3.eth.net.getId();
        console.log(`networkId ${idOfNetwork}`)
        infos.clientAccount = await window.ethereum.request({ method: 'eth_accounts' });
        infos.networkId = idOfNetwork
    } catch (ex) {
        console.log("No premission or network")
    }
    /// sepoia 11155111
    /// main 1
     /*if(idOfNetwork != 1 || window.ethereum == undefined || idOfNetwork == undefined || infos.clientAccount.length == 0) {
        const info_contract = (<div><p style={{"font-size": "18px"}}>Smart Contract to Verify: <a href="https://etherscan.io/address/0x3f2f376251096426a64b41a3ca35de77d14cb604">0x3f2f376251096426a64b41a3ca35de77d14cb604</a></p></div>)
        ReactDOM.render(info_contract, document.getElementById('infos_contract'))
        const info_contract_loading = (<div>Please use Metamask and ETH Mainnet to Interact with the Smart Contract<br /> <button class="glow-on-hover" style={{width: "400px", "fontSize": "18px"}} onClick={connectMM}>Connect with Metamask</button></div>)
        ReactDOM.render(info_contract_loading, document.getElementById('whiteListInfo'))
    } else if (idOfNetwork == 1) {
         const info_contract = (<div><p style={{"font-size": "15px"}}>You are connected with the wallet: {infos.clientAccount[0]} </p><p style={{"font-size": "18px"}}>Or mint over our Smart Contract: <a href="https://etherscan.io/address/0x3f2f376251096426a64b41a3ca35de77d14cb604">0x3f2f376251096426a64b41a3ca35de77d14cb604</a></p></div>)
         ReactDOM.render(info_contract, document.getElementById('infos_contract'))

         
    }*/
    const mint = ( <ul id="" class="style1 buttons"><li><a href="https://opensea.io/de-DE/collection/nakachads" class="button n01"><span class="label">Check Collection On openSea</span></a></li></ul>)
    ReactDOM.render(mint, document.getElementById('whiteListInfo'))
}

start();



export default function Infos() {
    return (
        
            <div>

                   

                   <div  style={{"wordWrap": "break-word", "font-size": "25px"}}>
                   {/*<p><b>Mint day</b> will be <i className="rainbowText"> JUNE 30th </i></p>*/}
                   <p id="whiteListInfo"> </p><br /> 
                   <p id="whiteListInfo2"> </p><br /> 
                   </div>
                   <div id="infos_contract" style={{"wordWrap": "break-word"}}>  </div>
                   <div id="error" style={{"wordWrap": "break-word"}}>  </div>

            </div>
        
    )
}
