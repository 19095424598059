import Web3 from 'web3';
var provider;
if (window.web3 == undefined) {
    provider = window.ethereum
} else {
    provider = window.ethereum
}
const web3 = new Web3(provider);
window.addEventListener("load", function() {
    if (window.ethereum) {
        // use MetaMask's provider
        
        // detect Metamask account change
        window.ethereum.on('accountsChanged', function(accounts) {
            console.log('accountsChanges', accounts);
            //window.location.reload(true);
        });
        // detect Network account change
        window.ethereum.on('chainChanged', function(networkId) {
            console.log('chainChanged', networkId);
            //window.location.reload(true);
        });
    }
});
export default web3;