import React from 'react'
import banner from '../../assets/header.png';
import { Link } from 'react-router-dom';
import About from '../about/About';
import Infos from '../infos/Infos';
import RoadMap from '../road-map/RoadMap';
import Faq from '../faq/Faq';
import Teaser from '../teaser/Teaser';
import Team from '../team/Team';
import twitterIcon from '../../assets/twitter.png';
import discordIcon from '../../assets/discord.png';


export default function Home() {
    return (


        <>
            <Infos />
          
        </>
    )
}
